var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","bottom":"","overlay-opacity":0,"right":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h3',{staticClass:"text-center"},[_vm._v("ТЕМА")])])],1)],1),_c('v-divider'),_c('v-subheader',[_vm._v("КОЛІР")]),(!_vm.$vuetify.theme.dark)?_c('v-item-group',{staticClass:"d-flex justify-center",attrs:{"mandatory":""},model:{value:(_vm.$vuetify.theme.themes.light.primary),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme.themes.light, "primary", $$v)},expression:"$vuetify.theme.themes.light.primary"}},_vm._l((_vm.colorsLight),function(color,key){return _c('v-item',{key:key,attrs:{"value":color},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-avatar',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"color":color,"outlined":active,"size":"48"},on:{"click":[function($event){return _vm.setColorLight(color)},function($event){$event.stopPropagation();return toggle($event)}]}})]}}],null,true)})}),1):_c('v-item-group',{staticClass:"d-flex justify-center",attrs:{"mandatory":""},model:{value:(_vm.$vuetify.theme.themes.dark.primary),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme.themes.dark, "primary", $$v)},expression:"$vuetify.theme.themes.dark.primary"}},_vm._l((_vm.colorsDark),function(color,key){return _c('v-item',{key:key,attrs:{"value":color},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-avatar',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"color":color,"outlined":active,"size":"48"},on:{"click":[function($event){return _vm.setColorDark(color)},function($event){$event.stopPropagation();return toggle($event)}]}})]}}],null,true)})}),1),_c('v-subheader',[_vm._v("ТЕМА")]),_c('v-item-group',{staticClass:"d-flex justify-center",attrs:{"mandatory":""},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}},_vm._l((_vm.themes),function(theme,key){return _c('v-item',{key:key,attrs:{"value":theme.dark},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-2",staticStyle:{"cursor":"pointer"},attrs:{"color":theme.color,"size":"48","dark":theme.dark},on:{"click":[function($event){return _vm.setDarkTheme(theme.dark)},function($event){$event.stopPropagation();return toggle($event)}]}},[_vm._v(" "+_vm._s(theme.title)+" ")])]}}],null,true)})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }