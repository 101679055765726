<template>
    <v-navigation-drawer
      v-model="drawer" 
      fixed
      temporary
      
      bottom
      :overlay-opacity="0"
      right
    >
    <div>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><h3 class="text-center">ТЕМА</h3></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader>КОЛІР</v-subheader>
      <v-item-group v-if="!$vuetify.theme.dark"
        v-model="$vuetify.theme.themes.light.primary"
        class="d-flex justify-center"
        mandatory
        >
            <v-item
            v-for="(color, key) in colorsLight"
            :key="key"
            :value="color"
            >
            <template v-slot="{ active, toggle }">
                <v-avatar
                :color="color"
                :outlined="active"
                class="ma-2"
                size="48"
                style="cursor: pointer;"
                @click="setColorLight(color)"
                @click.stop="toggle"
                />
            </template>
        </v-item>
      </v-item-group>
      <v-item-group v-else
        v-model="$vuetify.theme.themes.dark.primary"
        class="d-flex justify-center"
        mandatory
        >
            <v-item
            v-for="(color, key) in colorsDark"
            :key="key"
            :value="color"
            >
            <template v-slot="{ active, toggle }">
                <v-avatar
                :color="color"
                :outlined="active"
                class="ma-2"
                size="48"
                style="cursor: pointer;"
                @click="setColorDark(color)"
                @click.stop="toggle"
                />
            </template>
        </v-item>
      </v-item-group>
    <v-subheader>ТЕМА</v-subheader>
    <v-item-group
        v-model="$vuetify.theme.dark"
        class="d-flex justify-center"
        mandatory
        >
            <v-item
            v-for="(theme, key) in themes"
            :key="key"
            :value="theme.dark"
            >
            <template v-slot="{ toggle }">
                <v-btn
                :color="theme.color"
                class="ma-2"
                size="48"
                style="cursor: pointer;"
                :dark="theme.dark"
                @click="setDarkTheme(theme.dark)"
                @click.stop="toggle"
                >
                {{ theme.title }}
                </v-btn>
            </template>
        </v-item>
    </v-item-group>
    </div>
    </v-navigation-drawer>
</template>

<script>
  export default {
    data () {
      return {
        drawer: null,
        colorsLight: [
          '#37474F',
          //'#39a272',
          "#3f6273",
          "#2f3132",
          //"#6b4d7a",
          //"#43274d",
          "#4a374f",
          //'#23348e',
          //'#6324b6',
          //'#39a4f4',
          //'#43d5a2',
          //'#f8b138',
          //'#f65c40'
        ],
        colorsDark: [
          //'#37474F',
          //'#39a272',
          //'#6899b1',
          '#65769d',
          '#78659d',
          '#658a9d',
          //"#3f6273",
          //"#2f3132",
          //"#6b4d7a",
          //"#43274d",
          //'#23348e',
          //'#6324b6',
          //'#39a4f4',
          //'#43d5a2',
          //'#f8b138',
          //'#f65c40'
        ],
        themes:[
            {title: 'dark', color: 'black', dark: true},
            {title: 'light', color: '', dark: false},
        ]
      }
    },
    methods: {
        setColorLight(color){
          this.$vuetify.theme.themes.light.secondary = color
          localStorage.setItem("primary-color", color);
          localStorage.setItem("secondary-color", color);
        },
        setColorDark(color){
          localStorage.setItem("dark-primary-color", color);
        },
        setDarkTheme(dark){
          localStorage.setItem('dark-theme', dark) 
        }
    }
  }
</script>
